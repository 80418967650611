class Confetti
{
    constructor(){      
        this.maxParticleCount = 100; //set max confetti count
        this.particleSpeed = 2; //set the particle animation speed        
    
        this.colors = ["#03FBB4", "#02D1FF", "#855AFF", "#F907F3" ]
        this.streamingConfetti = false;
        this.animationTimer = null;
        this.particles = [];
        this.waveAngle = 0;  
		
		// this.wrapper = document.querySelector('.App');
		// this.appWidth = this.wrapper.offsetWidth;
        // this.appHeight = this.wrapper.offsetHeight; 
		this.appWidth = window.innerWidth;
        this.appHeight = window.innerHeight; 		

        this.init(); 		      
    }

    init(){  
        // if (this.wrapper === null || this.wrapper === undefined) {
        //     console.log('no wrapper found')    
        //     return;  
        // }
                
        this.canvas = document.getElementById('confetti-canvas'); 
		console.log('this.canvas', this.canvas);

        if (this.canvas === null) {
			this.canvas = document.createElement("canvas");
			this.canvas.setAttribute("id", "confetti-canvas");
			this.canvas.setAttribute("style", "display:block;z-index:999999;pointer-events:none");
            this.canvas.width = this.appWidth;
            this.canvas.height = this.appHeight;
			this.watchResize(); 
			document.body.appendChild(this.canvas);            
        }
        else {
            this.canvas.width = this.appWidth;
            this.canvas.height = this.appHeight;
        }         

        // window.addEventListener("resize", function() {
        //     this.canvas.width = this.appWidth;
        //     this.canvas.height = this.appHeight;
        // }, true);
        
    }

    resetParticle(particle, width, height) {
		particle.color = this.colors[(Math.random() * this.colors.length) | 0];
        particle.x = Math.random() * width;
        particle.y = Math.random() * height - height;
        particle.diameter = Math.random() * 10 + 5;
        particle.tilt = Math.random() * 10 - 10;
        particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
        particle.tiltAngle = 0;
		return particle;
	}

    startConfetti() {		
        console.log('startConfetti');
		window.requestAnimFrame = (function() {
			return window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				function (callback) {
					return window.setTimeout(callback, 16.6666667);
				};
		})(); 
        
        

		let context = this.canvas.getContext("2d");
        
		while (this.particles.length < this.maxParticleCount)
            this.particles.push(this.resetParticle({}, this.appWidth, this.appHeight));
		this.streamingConfetti = true;
		if (this.animationTimer === null) {
            const that=this;
			(function runAnimation() {
				context.clearRect(0, 0, that.appWidth, that.appHeight);				
				if (that.particles.length === 0)
                    that.animationTimer = null;
				else {
					that.updateParticles();
					that.drawParticles(context);
					that.animationTimer = window.requestAnimFrame(runAnimation);
				}
			})();
		}
	}

    stopConfetti() {
		this.streamingConfetti = false;
	}

	removeConfetti() {
		this.stopConfetti();
		this.particles = [];
	}

	toggleConfetti() {
		if (this.streamingConfetti)
            this.stopConfetti();
		else
            this.startConfetti();
	}

    drawParticles(context) {
		var particle;
		var x;
		for (var i = 0; i < this.particles.length; i++) {
			particle = this.particles[i];
			context.beginPath();
			context.lineWidth = particle.diameter;
			context.strokeStyle = particle.color;
			x = particle.x + particle.tilt;
			context.moveTo(x + particle.diameter / 2, particle.y);
			context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
			context.stroke();
		}
	}

    updateParticles() {
		var width = this.appWidth;
		var height = this.appHeight;
		var particle;
		this.waveAngle += 0.01;
		for (var i = 0; i < this.particles.length; i++) {
			particle = this.particles[i];
			if (!this.streamingConfetti && particle.y < -15)
				particle.y = height + 100;
			else {
				particle.tiltAngle += particle.tiltAngleIncrement;
				particle.x += Math.sin(this.waveAngle);
				particle.y += (Math.cos(this.waveAngle) + particle.diameter + this.particleSpeed) * 0.5;
				particle.tilt = Math.sin(particle.tiltAngle) * 15;
			}
			if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
				if (this.streamingConfetti && this.particles.length <= this.maxParticleCount)
                    this.resetParticle(particle, width, height);
				else {
					this.particles.splice(i, 1);
					i--;
				}
			}
		}
	}

	watchResize(){
		window.addEventListener("resize", function() {
			this.appWidth = window.innerWidth;
			this.appHeight = window.innerHeight;
		}, true);
	}
}

export default Confetti;