import React, { useEffect, useTransition, useState, lazy } from 'react';
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TestPorfolio = lazy(() => import("../portfolio-items/test"));


function HorizontalSection() {

    const [, startTransition] = useTransition();
    const [load, setLoad] = useState(false);


    useEffect(() => {       

        const animWrap = document.querySelector('.horizontal__content');       
        const getToValue = () => -(animWrap.scrollWidth - window.innerWidth); 

        gsap.fromTo(animWrap,
          { 
            x: () => 0 
          }, 
          { 
            x: () => getToValue(), 
            ease: "none",
            scrollTrigger: {
              trigger: '#horizontal',
              start: "center center",
              end: () => "+=" + (animWrap.scrollWidth - window.innerWidth),
              pin: '.horizontal__pinwrap',
              invalidateOnRefresh: true,
              anticipatePin: 1,
              scrub: true,
            //   markers: true
            }
          });       


    });

    return (
        <section id="horizontal" className="horizontal overflow-hidden min-h-screen section-padding-y relative">
            <div className="horizontal__pinwrap flex h-full">
                <div className="horizontal__content flex items-center">
                    <div className="horizontal__item"  
                        onClick={() => {
                            startTransition(() => {
                                setLoad(true);
                            });
                        }}
                    >
                        <img className="rounded-[24px] shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-bodog.jpg'} alt="Client work" />
                        <h4 className="">Bodog</h4>
                    </div>
                    <div className="horizontal__item">
                        <img className="rounded-[24px] shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-jager.jpg'} alt="Client work" />
                        <h4 className="">Jagermeister</h4>
                    </div>
                    <div className="horizontal__item">
                        <img className="rounded-lg shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-coop.jpg'} alt="Client work" />
                        <h4 className="">Co-op Insurance</h4>
                    </div>
                    <div className="horizontal__item">
                        <img className="rounded-[24px] shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-bodog.jpg'} alt="Client work" />
                        <h4 className="">Bodog</h4>
                    </div>
                    <div className="horizontal__item">
                        <img className="rounded-[24px]  shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-jager.jpg'} alt="Client work" />
                        <h4 className="">Jagermeister</h4>
                    </div>
                    <div className="horizontal__item">
                        <img className="rounded-lg  shrink-0 h-full w-full object-cover" src={process.env.PUBLIC_URL + '/assets/work-coop.jpg'} alt="Client work" />
                        <h4 className="">Co-op Insurance</h4>
                    </div>
                </div>
            </div>

            {load && <TestPorfolio />}
        </section>

        
    );
  }
  
  export default HorizontalSection;