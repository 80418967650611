function FeaturedSection() {
    return (
        <section id="featured" className="text-white overflow-hidden">
            <div className="pt-16 lg:pt-32 flex flex-col items-center justify-center h-full">
                <div className="marquee relative overflow-hidden flex gap-16 w-full ">
                    <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex items-center">
                        <h6 className="">Featured work</h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                        <h6 className="">Featured work</h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                    </div>
                    <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex items-center">
                        <h6 className="">Featured work</h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                        <h6 className="">Featured work</h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default FeaturedSection;