function ClientsSection() {
    return (
        <section id="clients" className="text-white min-h-screen -mt-32 overflow-hidden flex flex-col justify-center">
        <div className="section-padding-y flex flex-col items-center justify-center h-full space-y-16 lg:space-y-32">
            <div className="grid grid-cols-12 ">
                <div className="col-span-12 lg:col-span-6 lg:col-start-4 text-center">
                    <h2 className="">It's why we've built long standing relationships with some of the best</h2>
                </div>
            </div>
            <div className="marquee relative overflow-hidden flex gap-16">
                <div className="top-0 bottom-0 w-64 left-0 absolute bg-gradient-to-r from-black to-transparent z-20"></div>
                <div className="top-0 bottom-0 w-64 right-0 absolute bg-gradient-to-l from-black to-transparent z-20"></div>
                <div className="top-0 bottom-0 w-64 left-0 absolute bg-gradient-to-r from-black to-transparent z-20"></div>
                <div className="top-0 bottom-0 w-64 right-0 absolute bg-gradient-to-l from-black to-transparent z-20"></div>
    
                <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex">
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                </div>
                <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex">
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                    <img className="w-40 lg:w-56 h-auto self-start logo" src={process.env.PUBLIC_URL + '/assets/logo-moorepay.png'} />
                </div>
    
            </div>
        </div>
    </section>
    );
  }
  
  export default ClientsSection;