function HeroSection() {
    return (
        <section id="message-hero" className="section h-screen relative overflow-hidden">
        <div className="jumbo absolute -inset-[10px] opacity-50"></div>
        <div className="h-full relative z-10 flex items-center">
            <div id="hero-reel" className="">
                <div className="flex flex-col items-center justify-center h-full space-y-16 lg:space-y-32">
                    <div className="marquee relative flex gap-16 opacity-60">

                        <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex">
                            <img className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work1.jpg'} />
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-salford.jpg'} />
                            <video className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" muted autoplay playsinline loop>
                                <source src={process.env.PUBLIC_URL + '/assets/mp-720.mp4'} />
                            </video>
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-gshock.jpg'} />
                            <img className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-zendesk.jpg'} />
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-co-op.jpg'} />
                        </div>
                        <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex">
                            <img className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work1.jpg'} />
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-salford.jpg'} />
                            <video className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" muted autoplay playsinline loop>
                                <source src={process.env.PUBLIC_URL + '/assets/mp-720.mp4'} />
                            </video>
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-gshock.jpg'} />
                            <img className="flex-1 -mt-12 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-zendesk.jpg'} />
                            <img className="flex-1 h-[52vh] w-auto rounded-[24px]" src={process.env.PUBLIC_URL + '/assets/work-co-op.jpg'} />
                        </div>

                    </div>
                </div>
            </div>

            <div className="absolute bottom-4 left-4 lg:bottom-16 lg:left-16 space-y-6 lg:space-y-12 text-white lg:w-1/2">
                <h1 id="tone-agency" className="glitch text-7xl 2xl:leading-[0.85] 2xl:text-[10rem] opacity-90">TONE AGENCY</h1>
                <div className="caption space-y-4 lg:space-y-6">
                    <p className="text-xl 2xl:text-3xl font-semibold">For us, some things are non-negotiable.</p>
                    <p className="text-xl 2xl:text-3xl 2xl:leading-snug">Anyone can say they're world class. <span className="block">Here's what it means to us...</span></p>
                </div>
            </div>
        </div>
    </section>
    );
  }
  
  export default HeroSection;