import React, { useEffect } from 'react';
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


function PinRightCards() {

    useEffect(() => {

        console.log('PinRightCards');

        
        let workInfoItems = document.querySelectorAll('.work__photo-item');
        workInfoItems.forEach(function (item, index) {
            item.style.zIndex = workInfoItems.length - index;
        });
    
        gsap.set(".work__photo-item", {
            clipPath: function () {
                return "inset(0px 0px 0px 0px)"
            }
        });

        const animation = gsap.to('.work__photo-item:not(:last-child)', {
            clipPath: function () {
                return "inset(0px 0px 100%  0px)"
            },
            stagger: .5,
            ease: "none"
        });

        ScrollTrigger.create({
            trigger: '.work',
            start: 'top top',
            end: 'bottom bottom',
            pin: '.work__right-b1',
            animation: animation,
            scrub: 1
        });
        // ScrollTrigger.create({
        //     trigger: '.work',
        //     start: 'top top',
        //     end: 'bottom bottom',
        //     animation: animation,
        //     scrub: 1
        // });
        
       

    });

    return (   
        <section id="pin-right-cards">

            <div className="spacer"></div>
            
            <div className="work">
                <div className="work__left">

                    <div className="work__text pr-4">
                        <div className="work__info">
                            <div className="work__left-b1">
                                <h3 className="font-bold text-3xl lg:text-5xl mb-6 py-3">High-grade design</h3>
                                <p>Proin tempus scelerisque purus, ac aliquam turpis pellentesque sed. Nulla eget metus quis orci maximus malesuada. Curabitur vitae mattis erat.</p>
                                <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                            </div>
                        </div>
                        <div className="work__info">
                            <div className="work__left-b1">
                                <h3 className="font-bold text-3xl lg:text-5xl mb-6 py-3">Slick delivery processes</h3>
                                <p>Proin tempus scelerisque purus, ac aliquam turpis pellentesque sed. Nulla eget metus quis orci maximus malesuada. Curabitur vitae mattis erat.</p>
                                <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                            </div>
                        </div>
                        <div className="work__info">
                            <div className="work__left-b1">
                                <h3 className="font-bold text-3xl lg:text-5xl mb-6 py-3">Flexible attitude</h3>
                                <p>Proin tempus scelerisque purus, ac aliquam turpis pellentesque sed. Nulla eget metus quis orci maximus malesuada. Curabitur vitae mattis erat.</p>
                                <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                            </div>
                        </div>
                        <div className="work__info">
                            <div className="work__left-b1">
                                <h3 className="font-bold text-3xl lg:text-5xl mb-6 py-3">Genuine partnerships</h3>
                                <p>Proin tempus scelerisque purus, ac aliquam turpis pellentesque sed. Nulla eget metus quis orci maximus malesuada. Curabitur vitae mattis erat.</p>
                                <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="work__right">

                    <div className="work__right-b1">
                        <div className="work__photo">
                            <div className="work__photo-item" title="1">
                                <img src={process.env.PUBLIC_URL + '/assets/work.png'} />
                            </div>
                            <div className="work__photo-item" title="2">
                                <img src={process.env.PUBLIC_URL + '/assets/work2.png'} />
                            </div>
                            <div className="work__photo-item" title="3">
                                <img src={process.env.PUBLIC_URL + '/assets/work3.png'} />
                            </div>
                            <div className="work__photo-item" title="4">
                                <img src={process.env.PUBLIC_URL + '/assets/work4.png'} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="spacer"></div>
        </section>     
    );
  }
  
  export default PinRightCards;