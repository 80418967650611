import React, { useEffect } from 'react';
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


function ShowreelSection() {

    useEffect(() => {
        
        const cards = gsap.utils.toArray(".card-item");
        const spacer = 20;
        const minScale = 0.8;

        const distributor = gsap.utils.distribute({
            base: minScale,
            amount: 0.2
          });
          
        cards.forEach((card, index) => {
            const scaleVal = distributor(index, cards[index], cards);
            console.log('scaleVal', index, scaleVal)
            
            const tween = gsap.to(card, {
                scrollTrigger: {
                    trigger: card,
                    start: `top top`,
                    scrub: true,
                    // markers: true,
                    invalidateOnRefresh: true
                },
                ease: "none",
                scale: scaleVal
            });
            
            ScrollTrigger.create({
                trigger: card,
                start: `top-=${index * spacer} top`,
                endTrigger: "#showreal-section",
                end: `bottom top+=${200 + cards.length * spacer}`,
                pin: true,
                pinSpacing: false,
                // markers: true,
                id: "pin",
                invalidateOnRefresh: true
            });
        });

    })


    return (        
        <section id="showreal-section" className="overflow-hidden min-h-screen section-padding-y relative">
        <div className="section-padding-x space-y-12">
            <div className="card-stack">

                <div className="card-item">                    
                    <div className="card-body h-full flex flex-col lg:grid lg:grid-cols-12 lg:gap-16 bg-tone-green rounded-3xl">
                        <div className="col-span-5 custom-cursor">
                            <div className="flex flex-col h-full justify-center space-y-4 lg:space-y-8 px-4 py-4  lg:pl-16 text-black">
                                <h3 className="">Web/UX</h3>
                                <div className="paragraphs text-sm lg:text-xl space-y-4">
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6 col-start-7 p-4 lg:p-16 flex items-center justify-center">
                            <img className="w-full" src={process.env.PUBLIC_URL + '/assets/work.png'} />
                        </div>
                    </div>
                </div>

                <div className="card-item">
                    <div className="card-body h-full flex flex-col lg:grid lg:grid-cols-12 lg:gap-16 bg-tone-blue rounded-3xl">
                        <div className="col-span-5 custom-cursor">
                            <div className="flex flex-col h-full justify-center space-y-4 lg:space-y-8 px-4 py-4  lg:pl-16 text-black">
                                <h3 className="">Motion & Graphics</h3>
                                <div className="paragraphs text-sm lg:text-xl space-y-4">
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6 col-start-7 p-4 lg:p-16 flex items-center justify-center">
                            <img className="w-full" src={process.env.PUBLIC_URL + '/assets/work.png'} />
                        </div>
                    </div>
                </div>

                <div className="card-item">
                    <div className="card-body h-full flex flex-col lg:grid lg:grid-cols-12 lg:gap-16 bg-tone-purple rounded-3xl">
                        <div className="col-span-5 custom-cursor">
                            <div className="flex flex-col h-full justify-center space-y-4 lg:space-y-8 px-4 py-4  lg:pl-16 text-black">
                                <h3 className="">Design on Demand</h3>
                                <div className="paragraphs text-sm lg:text-xl space-y-4">
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6 col-start-7 p-4 lg:p-16 flex items-center justify-center">
                            <img className="w-full" src={process.env.PUBLIC_URL + '/assets/work.png'} />
                        </div>
                    </div>
                </div>

                <div className="card-item">
                    <div className="card-body h-full flex flex-col lg:grid lg:grid-cols-12 lg:gap-16 bg-tone-pink rounded-3xl">
                        <div className="col-span-5 custom-cursor">
                            <div className="flex flex-col h-full justify-center space-y-4 lg:space-y-8 px-4 py-4 lg:pl-16 text-black">
                                <h3 className="">Games</h3>
                                <div className="paragraphs text-sm lg:text-xl space-y-4">
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                    <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have So we thought we’d do that work of.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6 col-start-7 p-4 lg:p-16 flex items-center justify-center">
                            <img className="w-full" src={process.env.PUBLIC_URL + '/assets/work.png'} />
                        </div>
                    </div>
                </div>

                <div className="card-item">
                    <div id="showreel" className="card-body h-full grid grid-cols-12">
                        <div className="col-span-12 custom-cursor">
                            <img className="w-full" src={process.env.PUBLIC_URL + '/assets/showreel.png'} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </section>
    );
  }
  
  export default ShowreelSection;