import React, { useEffect } from 'react';
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function PeopleSection() {

    useEffect(() => {

        const section = document.querySelector('section#people');
        const imagesWrapper = document.querySelector('#people-images');        
        
        // apply parallax effect to any element with a data-speed attribute
        gsap.to("[data-speed]", {
            y: (i, el) => (-1 * parseFloat(el.getAttribute("data-speed"))) * (imagesWrapper.offsetHeight / 2), //smoothing to make sore it won't go too far outside section
            ease: "none",
            scrollTrigger: {
                trigger:imagesWrapper,
                invalidateOnRefresh: true,
                scrub: 0,               
                start: 'center bottom'
            }
        });

        //make text sticky
        ScrollTrigger.create({
            trigger: '#people-text',
            start: `center center`,            
            endTrigger: `#${section.nextElementSibling.id}`,
            end: 'top bottom',          
            pin: true,  
            invalidateOnRefresh: true,
            // scrub: 1
        });        

    });

    return (
        <section id="people" className="text-white overflow-hidden">
            <div className="section-padding-y flex flex-col items-center justify-center h-full space-y-16 lg:space-y-32 relative">
                <div id="people-text" className="grid grid-cols-12 z-50">
                    <div className="col-span-12 lg:col-span-6 lg:col-start-4 text-center">
                        <h2 className="">It's why people thrive here, and stay here</h2>
                    </div>
                </div>
                <div id="people-images" className="images relative inset-0 col-span-12 z-40">
                    <img data-speed="0.6" src="https://images.unsplash.com/photo-1556856425-366d6618905d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG5lb258ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60" alt="" />
                    <img data-speed="0.7" src="https://images.unsplash.com/photo-1520271348391-049dd132bb7c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80" alt="" />
                    <img data-speed="1" src="https://images.unsplash.com/photo-1609166214994-502d326bafee?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=80" alt="" />
                    <img data-speed="1.2" src="https://images.unsplash.com/photo-1589882265634-84f7eb9a3414?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=434&q=80" alt="" />
                    <img data-speed="0.9" src="https://images.unsplash.com/photo-1514689832698-319d3bcac5d5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=434&q=80" alt="" />
                    <img data-speed="1.4" src="https://images.unsplash.com/photo-1535207010348-71e47296838a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80" alt="" />
                    <img data-speed="0.8" src="https://images.unsplash.com/photo-1588007375246-3ee823ef4851?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fG5lb258ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60" alt="" />
                    <img data-speed="1" src="https://images.unsplash.com/photo-1571450669798-fcb4c543f6a4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fG5lb258ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60" alt="" />
                </div>
                
            </div>
        </section>
    );
  }
  
  export default PeopleSection;