// import logo from './logo.svg';
// import './App.css';
import React, { useEffect } from 'react';
import Lenis from '@studio-freight/lenis'

import Header from "./components/Header";
import HeroSection from "./sections/HeroSection";
// import FourPillarsSection from "./sections/FourPillarsSection";
import PinRightCards from "./sections/PinRightCards";
import ArrowDownSection from "./sections/ArrowDownSection";
import ClientsSection from "./sections/ClientsSection";
import PeopleSection from "./sections/PeopleSection";
import ShowreelSection from "./sections/ShowreelSection";
import FeaturedSection from "./sections/FeaturedSection";
import HorizontalSection from "./sections/HorizontalSection";
import MethodologySection from "./sections/MethodologySection";
import HelloSection from "./sections/HelloSection";

import Confetti from './scripts/Confetti';


function App() {

  useEffect(() => {  
      
    const lenis = new Lenis ( {
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })
    //get scroll value
    // lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
    // console. log({ scroll, limit, velocity, direction, progress })
    // })

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)


    // init confettie animation
    const Confettie = new Confetti();   

    const button = document.getElementById("book-a-call");
    const buttonFooter = document.getElementById("book-a-call-footer");

    button.addEventListener("mouseover", function() {
      Confettie.startConfetti();
    });   
    button.addEventListener("mouseout", function() {
      Confettie.stopConfetti();
    }); 			
		buttonFooter.addEventListener("mouseover", function() {
			Confettie.startConfetti();
		});
		buttonFooter.addEventListener("mouseout", function() {
			Confettie.stopConfetti();
		});

  });

  return (
    <div className="App">
      <Header />

      <HeroSection />
      <PinRightCards />
      <ArrowDownSection />
      <ClientsSection />
      <PeopleSection />
      <ShowreelSection />
      <FeaturedSection />
      <HorizontalSection />
      <MethodologySection />
      <HelloSection />
      
      <canvas id="confetti-canvas" />

    </div>
  );
}

export default App;
