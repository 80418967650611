function Header() {
    
  return (
    <header id="header" className=" inset-x-0 fixed top-0 z-50">
        <nav className="py-4 md:py-5 inset-x-0">
            <div class='section-padding-x'>
                <div className="flex flex-wrap justify-between items-center mx-auto">
                    <div className="logo-wrapper flex items-center lg:min-w-[268px]">
                        <svg className="h-6 md:h-8 w-auto" width="116px" height="45px" viewBox="0 0 116 45" version="1.1">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="MC-Sport-1" transform="translate(-37.000000, -38.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                    <g id="Group-3" transform="translate(37.000000, 38.000000)">
                                        <g id="Group">
                                            <path d="M4.346723,18.729721 L0,18.729721 L0,15.5675429 C7.41510297,12.8918813 13.0402629,4.62154873 13.5515813,0 L15.0857243,0 L15.0857243,13.8649141 L21.733615,13.8649141 L21.733615,18.729721 L15.0857243,18.729721 L15.0857243,35.7567239 C15.0857243,39.2837893 17.0672593,40.8648784 19.4324473,40.8648784 C20.327325,40.8648784 21.2861292,40.5608056 22.2450273,40.1351038 L23.0121457,41.5945637 C20.7108842,43.5404508 18.1540104,45 13.5515813,45 C6.64798451,45 4.346723,41.1081366 4.346723,36.2432403 L4.346723,18.729721 Z" id="Path"></path>
                                            <path d="M20.6639676,28.9218119 C20.6639676,19.4211091 26.7331107,12.6 37.1012146,12.6 C48.2280078,12.6 53.5384615,18.6902377 53.5384615,28.6781881 C53.5384615,38.1789803 47.4693185,45 37.1012146,45 C25.9745142,45 20.6639676,38.9096729 20.6639676,28.9218119 Z M42.4117612,28.6781881 C42.4117612,21.8571684 41.6531647,15.7669307 37.1013074,15.7669307 C33.055243,15.7669307 31.7908537,20.8826731 31.7908537,28.9217225 C31.7908537,35.7427422 32.5494501,41.8330693 37.1013074,41.8330693 C41.1473718,41.8330693 42.4117612,36.7172374 42.4117612,28.6781881 Z" id="Shape"></path>
                                            <path d="M53.1091111,42.8727389 C55.4036614,42.6272686 55.6585283,40.6636869 55.6585283,38.700015 L55.6585283,22.0091196 C55.6585283,19.7999775 54.893647,18.3273362 52.5991903,18.081866 L52.5991903,16.8546048 C57.952985,16.3636644 61.7771106,14.1546124 65.0915027,11.7 L66.3661176,11.9454702 L66.3661176,17.2228102 C68.724408,14.338715 73.2496749,12.1909405 77.583815,12.1909405 C83.1925701,12.1909405 85.7420809,15.6273437 85.7420809,21.5182692 L85.7420809,38.700015 C85.7420809,40.6636869 86.2519081,42.6272686 88.291498,42.8727389 L88.291498,44.1 L72.4849808,44.1 L72.4849808,42.8727389 C74.7794375,42.6272686 75.0343979,40.6636869 75.0343979,38.700015 L75.0343979,22.9908203 C75.0343979,20.290918 73.2498621,18.8181866 70.7003514,18.8181866 C69.3619027,18.8181866 67.8959738,19.0022893 66.3663048,19.6772423 L66.3663048,38.700015 C66.3663048,40.6636869 66.8762257,42.6272686 68.9158155,42.8727389 L68.9158155,44.1 L53.1091111,44.1 L53.1091111,42.8727389 Z" id="Path"></path>
                                            <path d="M98.3168684,27.460069 C98.7605546,35.8036481 101.67602,40.127792 106.366138,40.127792 C108.584382,40.127792 112.197069,38.6661385 113.97172,35.7428316 L115.492837,35.9864554 C114.225162,41.5895349 109.154836,45 103.577439,45 C94.1338211,45 87.3522267,40.1886979 87.3522267,29.6525939 C87.3522267,17.2285842 94.7042747,12.6 102.816928,12.6 C110.42251,12.6 116,17.7158318 116,24.5367621 L116,26.9728214 L115.49293,27.460069 L98.3168684,27.460069 Z M98.2534847,24.597668 L104.084416,24.2931383 C105.352091,24.2322323 106.366138,23.0750192 106.366138,20.6390493 C106.366138,17.4721186 105.605533,14.7924355 102.563393,14.7924355 C98.6971708,14.7924355 98.2534847,20.0909852 98.2534847,24.597668 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="flex items-center md:order-2">
                        <a id="book-a-call" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/toneagency/designertoday-demo'});return false;" href="" className="btn btn-2 button-primary ml-4">Book a Call</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
  );
}

export default Header;