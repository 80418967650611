import React, { useEffect } from 'react';
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


function MethodologySection() {

    useEffect(() => {

        // hide header button 
        let tl = gsap.timeline({         
            scrollTrigger: {
                trigger: '#methodology',
                start: `75% top`,                       
                end: '90% top',                           
                // markers: true,            
                invalidateOnRefresh: true,
                toggleActions: 'play none reverse none'
                
                // scrub: 1
            },
          });    
        tl.to("#book-a-call", { opacity: 0 });    
        
    })
    return (
        <section id="methodology" className="min-h-screen mt-[-1px]">
            <div className="section-padding-y section-padding-x flex flex-col items-center justify-center lg:h-full space-y-16 lg:space-y-32">
                <div className="lg:grid lg:grid-cols-12 space-y-16 lg:space-y-32">
                    <div className="col-span-12 lg:col-span-8 lg:col-start-3 text-center">
                        <h2 className="">We even give away our methodology. Graft plus craft</h2>
                    </div>
                    <div className="flex lg:grid lg:grid-cols-12 gap-12 lg:col-span-12 text-black ">

                        <div className="lg:col-span-10 lg:col-start-2 bg-purple-800 rounded-3xl text-white overflow-hidden relative">
                            <div className="absolute inset-0 object-cover bg-red-500">
                                <img className="w-full h-full object-cover object-right" src={process.env.PUBLIC_URL + '/assets/burger.png'} />
                            </div>
                            <div className="content p-12 space-y-5 w-1/2  min-h-[600px] flex flex-col relative z-10">
                                <h3 className="">28 Examples of Effective Gamification for Marketing Campaigns</h3>
                                <p>The most common question we’re asked is the equivalent of a ‘so what?’ - how are brands using gamification and what impact does it have?</p>
                                <p>So we thought we’d do that work of showing you.</p>
                            </div>
                        </div>

                        <div className="lg:col-span-5 lg:col-start-2 bg-white rounded-3xl">
                            <div className="content p-4 lg:p-12 space-y-5 lg:min-h-[600px]">
                                <img className="w-full" src={process.env.PUBLIC_URL + '/assets/art-of-design.png'} />
                                <h3 className="">The Art of Design Leadership</h3>
                                <p>Core principles and lessons from the leaders of the world's best design teams...</p>
                            </div>
                        </div>

                        <div className="lg:col-span-5 bg-yellow-300 rounded-3xl">
                            <div className="content p-4 lg:p-12 space-y-5 lg:min-h-[600px]">
                                <h3 className="">How does Design on Demand work</h3>
                                <p>What you need is a ‘plug and play’ design team when you need it...</p>
                                <img className="w-full" src={process.env.PUBLIC_URL + '/assets/art-of-design.png'} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default MethodologySection;