function HelloSection() {
    return (
        <section id="hello" className="section bg-white fixed inset-0 -z-10 text-black">    
            <div className="h-full flex items-center justify-center flex-col space-y-12">
                <div className="mx-auto flex items-center justify-center flex-col max-w-screen-lg text-center space-y-6 md:space-y-12">
                    <h2 className="px-4">Let’s create something amazing together</h2>
                    <div className="flex items-center md:order-2">
                        <a id="book-a-call-footer" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/toneagency/designertoday-demo'});return false;" href="" className="btn btn-2 button-primary ml-4">Book a Call</a>
                    </div>
                </div>
                <div className="marquee relative overflow-hidden flex gap-16  w-full ">
                    <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex items-center">
                        <h6 className="">
                            <a className="hover:text-tone-blue" href="#">studio@tone.co.uk</a>
                        </h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                        <h6 className="">
                            <a className="hover:text-tone-green" href="#">studio@tone.co.uk</a>
                        </h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                    </div>
                    <div className="marquee-content scroll shrink-0 min-w-full gap-16 flex items-center">
                        <h6 className="">
                            <a className="hover:text-tone-pink" href="#">studio@tone.co.uk</a>
                        </h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                        <h6 className="">
                            <a className="hover:text-tone-purple" href="#">studio@tone.co.uk</a>
                        </h6>
                        <img className="star" src={process.env.PUBLIC_URL + '/assets/star.svg'} />
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default HelloSection;